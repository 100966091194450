export const imageAltTextMap = {
  '/images/illustrations/3-students-standing.svg':
    'Three happy high school students with arms linked',
  '/images/website/adc-logo.svg': 'Anti Discrimination Council logo',
  '/images/slides/illustrations/against-the-crowd.svg': '',
  '/images/slides/activities/stereotype-class-discussion/age.jpg': '',
  '/images/slides/activities/alex.jpg': '',
  '/images/slides/activities/angela.jpg': '',
  '/images/slides/activities/anh.jpg': '',
  '/images/slides/tips/arrow-keys.svg': 'Left and right arrow keys highlighed for navigation',
  '/images/slides/activities/mark-it-up-newspaper/article.jpg': '',
  '/images/slides/illustrations/asian-maths-1.svg': '',
  '/images/slides/illustrations/asian-maths-2.svg': '',
  '/images/slides/illustrations/asian-maths-3.svg': '',
  '/images/slides/illustrations/asian-maths-4.svg': '4 students sitting and talking',
  '/images/slides/activities/atong.jpg': '',
  '/images/slides/illustrations/australian-teens-discrimination.png':
    'Discrimination types against Australian teens chart',
  '/images/slides/activities/stereotype-causes/autism-friend.svg':
    "Concern for autistic friend's social capability",
  '/images/slides/placeholder/avatar-age.png': '',
  '/images/slides/placeholder/avatar-appearance.png': '',
  '/images/slides/placeholder/avatar-disability.png': '',
  '/images/slides/placeholder/avatar-ethnicity.png': '',
  '/images/slides/placeholder/avatar-gender.png': '',
  '/images/slides/placeholder/avatar-profession.png': '',
  '/images/slides/placeholder/avatar-race.png': '',
  '/images/slides/placeholder/avatar-religion.png': '',
  '/images/slides/placeholder/avatar-sexuality.png': '',
  '/images/slides/placeholder/avatar-wealth.png': '',
  '/images/slides/activities/stereotype-causes/basketball.svg':
    'Stereotyping tall darker skin-toned people as basketballers',
  '/images/slides/activities/social-media-features/beautyfilter.png':
    'Female face with and without beauty filter activated',
  '/images/slides/activities/dog-breeds/border-collie.png': 'Border Collie',
  '/images/slides/illustrations/bus-crop.svg':
    'Lighter skin-toned bus passengers behaving differently towards darker skin-toned people',
  '/images/slides/illustrations/bus-ride.svg':
    'Lighter skin-toned bus passengers behaving differently towards darker skin-toned people',
  '/images/slides/activities/think-feel-care/care-cultural-background.svg': '',
  '/images/illustrations/care-her-appearance.svg': '',
  '/images/slides/activities/think-feel-care/care-her-appearance.svg': '',
  '/images/slides/activities/think-feel-care/care-past-experiences.svg': '',
  '/images/illustrations/care-social-relationships.svg': '',
  '/images/slides/activities/think-feel-care/care-social-relationships.svg': '',
  '/images/slides/activities/dog-breeds/cavalier.png': 'Cavalier King Charles',
  '/images/slides/activities/three-little-pigs/character-A.svg':
    'Grandma from Little Red Riding Hood',
  '/images/slides/activities/three-little-pigs/character-B.svg':
    'Woodsman from Little Red Riding Hood',
  '/images/slides/activities/three-little-pigs/character-C.svg': 'Wolf from Little Red Riding Hood',
  '/images/slides/activities/inclusivity-charts/chart1.jpg':
    'Percentage of overseas-born Australians 1891-2019 chart',
  '/images/slides/activities/inclusivity-charts/chart2.png':
    'First Second Third Generation Australians chart',
  '/images/slides/activities/inclusivity-charts/chart3.jpg':
    'Top 5 ancestries Australia 2016 chart',
  '/images/slides/activities/inclusivity-charts/chart4.png':
    'Top five languages spoken at home chart',
  '/images/slides/activities/chen.jpg': '',
  '/images/slides/activities/dog-breeds/chihuahua.png': 'Chihuahua',
  '/images/slides/illustrations/colour-exclusion.svg': '',
  '/images/slides/illustrations/community-distrust.svg': '',
  '/images/slides/illustrations/community-food.svg': 'Diverse community',
  '/images/slides/illustrations/community-mixed.svg': '',
  '/images/slides/illustrations/community-treatment.svg': '',
  '/images/illustrations/cuisine.svg': 'Diverse community',
  '/images/illustrations/cultural-festivities.svg': 'Five diverse community thumbnails',
  '/images/slides/activities/cyberbullying/cyber-1.svg': 'Social media image post',
  '/images/slides/activities/cyberbullying/cyber-2.svg': 'Online hate text messages - ableism',
  '/images/slides/activities/cyberbullying/cyber-3.svg': 'Online hate chat - islamaphobia',
  '/images/slides/activities/cyberbullying/cyber-4.svg': 'Online hate social media post - racism',
  '/images/slides/activities/cyberbullying/cyber-5.svg':
    'Online hate social media image post - homophobia',
  '/images/slides/illustrations/developmental-stereotypes.svg':
    'A female coding on a laptop and a male painting',
  '/images/slides/illustrations/digital-footprint.svg': '',
  '/images/slides/illustrations/digital-reputation.svg':
    'Female happily sitting on bean bag using her laptop',
  '/images/slides/activities/dimitrios.jpg': '',
  '/images/slides/photos/dis1-reveal1.jpg': 'Partial image - police with demonstrators',
  '/images/slides/photos/dis1-reveal2.jpg': 'Partial image - police with demonstrators',
  '/images/slides/photos/dis1-reveal3.jpg':
    'Dark skin-toned individual carrying lighter skin-toned individual.',
  '/images/slides/activities/stereotype-class-discussion/disability.jpg': '',
  '/images/slides/illustrations/discrimination-individual-fear.svg': '',
  '/images/slides/illustrations/discrimination-individual-pain.svg': '',
  '/images/slides/illustrations/discrimination-individual-sadness.svg': '',
  '/images/slides/illustrations/discrimination-individual.svg':
    'Multiple hands pointing threateningly at an individual',
  '/images/slides/illustrations/discrimination-retail.svg':
    'Retail worker serving one customer and discriminating against another',
  '/images/illustrations/diverse-friends.svg': 'Diverse community',
  '/images/slides/illustrations/diverse-friends.svg': '',
  '/images/slides/illustrations/diverse-people.svg': 'Diverse people',
  '/images/slides/illustrations/duck-rabbit.jpg': 'Illusion: Is it a duck or a rabbit?',
  '/images/website/dvir.jpg': 'Dr Dvir Abramovich, ADC Chairman',
  '/images/slides/activities/think-feel-care/ethan-phone.svg':
    "Ethan's phone displaying hateful messages",
  '/images/slides/activities/think-feel-care/ethan-portrait.svg': '',
  '/images/slides/activities/think-feel-care/ethan-reading.svg':
    'A passing driver yelling abuse towards Ethan',
  '/images/slides/illustrations/examples-of-discrimination.svg':
    'Sad female reading sitting on floor reading online hate social media posts',
  '/images/slides/activities/social-media-features/facebook.png':
    'Social media post displaying reactions to the post',
  '/images/slides/illustrations/female-engineers.svg': 'Female engineers',
  '/images/slides/illustrations/female-jobs.svg': 'Showcasing females in a range of jobs',
  '/images/slides/illustrations/free-speech.svg':
    'Hands holding megaphones and digital devices depicting free speech',
  '/images/slides/activities/stereotype-causes/gangs.svg':
    'Sterotyping a group of darker skin-toned people as terrorists',
  '/images/illustrations/gardening.svg': 'Diverse community members gardening',
  '/images/slides/activities/stereotype-class-discussion/gender.jpg':
    'Male child dressed in blue playing with pink-coloured toys',
  '/images/slides/activities/dog-breeds/german-shepard.png': 'German Shepard',
  '/images/slides/activities/stereotype-causes/group-african-kids.svg':
    'Female wary of group of African male youths',
  '/images/slides/activities/stereotype-causes/group-talking.svg': 'Group talking',
  '/images/slides/illustrations/hate-speech.svg': 'Person targeted with hate speech',
  '/images/slides/activities/media-headlines/headline-1.png': '',
  '/images/slides/activities/media-headlines/headline-2.png': '',
  '/images/slides/activities/media-headlines/headline-3.png': '',
  '/images/slides/illustrations/homeless.svg': 'A person stereotyping homeless people',
  '/images/slides/activities/image-reveal/image-1.svg': 'Diverse community',
  '/images/slides/activities/image-reveal/image-2.svg': 'Diverse community members gardening',
  '/images/slides/activities/image-reveal/image-3.svg': 'Five diverse community thumbnails',
  '/images/slides/activities/image-reveal/image-4.svg': 'People celebrating diversity',
  '/images/slides/activities/pick-the-stereotype/indigenous-1.jpg':
    'Female of Native American appearance',
  '/images/slides/activities/pick-the-stereotype/indigenous-2.jpg':
    'Michelle Obama - Female of African American appearance',
  '/images/slides/activities/pick-the-stereotype/indigenous-3.jpg':
    'Female of Caucasian appearance',
  '/images/slides/activities/pick-the-stereotype/indigenous-4.jpg': 'Female of African appearance',
  '/images/slides/illustrations/indigenous.svg':
    'Indigenous Australians proudly waving Aboriginal and Torres Straight Island flags',
  '/images/slides/illustrations/media/internet.svg': '',
  '/images/slides/activities/jack.jpg': '',
  '/images/slides/activities/pick-the-stereotype/jew-1.jpg': 'Female of Asian appearance',
  '/images/slides/activities/pick-the-stereotype/jew-2.jpg': 'Female of Indian appearance',
  '/images/slides/activities/pick-the-stereotype/jew-3.jpg':
    'Female of African American appearance',
  '/images/slides/activities/pick-the-stereotype/jew-4.jpg': 'Female wearing Wonder Woman costume',
  '/images/slides/activities/stereotype-causes/job-application.svg':
    'Employee stereotyping a race based on negative experience with employer',
  '/images/slides/activities/think-feel-care/josie-phone.svg':
    'Josie dancing social media video post',
  '/images/slides/activities/think-feel-care/josie-portrait.svg': '',
  '/images/slides/activities/think-feel-care/josie-reading.svg': 'Josie looking at phone',
  '/images/slides/activities/kiran.jpg': '',
  '/images/slides/activities/kylie.jpg': '',
  '/images/slides/illustrations/label-face-2.svg': 'Face plastered with hateful labels',
  '/images/slides/illustrations/label-face.svg': 'Face plastered with hateful labels',
  '/images/slides/illustrations/labels.svg': 'Face plastered with hateful labels',
  '/images/slides/tips/laptop-connection.png':
    'Depiction of computer being connected to the internet',
  '/images/slides/activities/leon.jpg': '',
  '/images/slides/illustrations/media/magazines.svg': '',
  '/images/slides/activities/matthew.jpg': '',
  '/images/slides/activities/chalk-talk-michelle/michelle.jpg': '',
  '/images/slides/illustrations/MM2_Activity_InfiniteScroll.gif': 'Infinite social media scrolling',
  '/images/slides/activities/pick-the-stereotype/muslim-1.jpg':
    'Male with short beard, wearing a pink turban.',
  '/images/slides/activities/pick-the-stereotype/muslim-2.jpg':
    'Male with longer beard, hair ringlets in front of ears and a skullcap.',
  '/images/slides/activities/pick-the-stereotype/muslim-3.jpg':
    'Person wearing full body black clothing with only eyes visible.',
  '/images/slides/activities/pick-the-stereotype/muslim-4.jpg':
    'Seated male with manicured beard wearing a suit',
  '/images/slides/illustrations/media/newspaper.svg': '',
  '/images/slides/photos/night-of-glass.jpg': 'Holocaust male child surrendering to Nazis',
  '/images/slides/activities/stereotype-causes/old-driver.svg':
    'Young people retelling a story about a car crashing into a shop front driven by elderly person',
  '/images/illustrations/online-hate-normal.svg':
    'Sad female reading sitting on floor reading online hate social media posts',
  '/images/slides/photos/online-hate-strategy-1.png': 'Online hate chat',
  '/images/slides/photos/online-hate-strategy-1.svg': 'Online hate chat',
  '/images/slides/photos/online-hate-strategy-2.png': 'Online hate chat',
  '/images/slides/photos/online-hate-strategy-2.svg': 'Online hate chat',
  '/images/slides/photos/online-hate-strategy-3.png': 'Reporting social media comment',
  '/images/slides/photos/online-hate-strategy-4.png': 'Blocked users list',
  '/images/slides/photos/online-hate-strategy-5.png': 'Online chat support',
  '/images/slides/photos/online-hate-strategy-6.png':
    'Organisations who can help with dealing with online hate',
  '/images/slides/photos/orthodox-jews.jpg': 'Orthodox Jews praying',
  '/images/illustrations/outsider.svg': 'Bystander',
  '/images/slides/activities/paul.jpg': '',
  '/images/illustrations/placeholder.svg': '',
  '/images/illustrations/protest-alt.svg': 'People celebrating diversity',
  '/images/illustrations/protest.svg': 'People celebrating diversity',
  '/images/slides/activities/stereotype-class-discussion/race.jpg': '',
  '/images/slides/illustrations/media/radio.svg': '',
  '/images/illustrations/reinforcer.svg': 'Reinforcer',
  '/images/slides/activities/ron.jpg': '',
  '/images/slides/activities/dog-breeds/rottweiler.png': 'Rottweiler',
  '/images/slides/activities/cyber-racism/scenario-1.svg': 'Black facing social media post',
  '/images/slides/activities/hate-scenarios/scenario-1.svg': '',
  '/images/slides/activities/cyber-racism/scenario-2.svg': 'Anti-semitic social media post',
  '/images/slides/activities/hate-scenarios/scenario-2.svg': '',
  '/images/slides/activities/cyber-racism/scenario-3.svg': 'Racist social media post',
  '/images/slides/activities/hate-scenarios/scenario-3.svg': '',
  '/images/slides/activities/hate-scenarios/scenario-4.svg': '',
  '/images/slides/activities/hate-scenarios/scenario-5.svg': '',
  '/images/slides/illustrations/self-awareness.svg': '',
  '/images/slides/activities/dog-breeds/sheep-dog.png': 'Old English Sheep Dog',
  '/images/illustrations/sidekick.svg': 'Sidekick',
  '/images/slides/activities/identify-discrimination/slide-1.svg':
    'Two people talking at a bus stop',
  '/images/slides/activities/identify-discrimination/slide-2.svg':
    'Male fixing a lamp and a female relaxing on a beanbag',
  '/images/slides/activities/identify-discrimination/slide-3.svg':
    'Person in wheelchair looking up at a staircase',
  '/images/slides/activities/social-media-features/snapchat.png': 'Snapchat streaks',
  '/images/slides/illustrations/social-influence.svg':
    'Live stream of girl demonstrating hate speech',
  '/images/slides/illustrations/social-media-1.svg': '',
  '/images/slides/illustrations/social-media-1b.svg': '',
  '/images/slides/illustrations/social-media-2.svg': '',
  '/images/slides/illustrations/social-media-3.svg': '',
  '/images/slides/photos/social-media-stats-instagram.png':
    'Positives and negatives of instagram infographic',
  '/images/slides/photos/social-media-stats-snapchat.png':
    'Positives and negatives of SnapChat infographic',
  '/images/slides/photos/social-media-stats-youtube.png':
    'Positives and negatives of YouTube infographic',
  '/images/slides/illustrations/media/social-media.svg': '',
  '/images/slides/activities/social-media-posts/social1.png':
    'Social media post Coronavirus - Carnivorous anagram',
  '/images/slides/activities/social-media-posts/social2.jpg': 'Social media post - beauty tips',
  '/images/slides/activities/social-media-posts/social3.jpg':
    'eSafety Commissioner web page - online experiences',
  '/images/slides/activities/social-media-posts/social4.jpg': 'WWF Donate Now social media post',
  '/images/slides/activities/social-media-posts/social5.jpg':
    'Social media post - connecting coronavirus with meat eating',
  '/images/slides/activities/social-media-posts/social6.jpg':
    'Social media post - environmental conservation',
  '/images/slides/activities/stereotype-causes/sports.svg':
    'Dejected female next to Sports Event sign up list',
  '/images/slides/activities/sung-ho.jpg': '',
  '/images/slides/activities/suroj.jpg': '',
  '/images/slides/illustrations/teacher-talking.svg': '',
  '/images/slides/activities/safe-websites/template1.jpg': '',
  '/images/slides/activities/safe-websites/template2.jpg': '',
  '/images/slides/activities/safe-websites/template3.jpg': '',
  '/images/slides/activities/think-feel-care/thought-bubble.svg': '',
  '/images/slides/activities/thoughtful-posts/thoughtful-1.svg':
    "Jewish male forced to lick another male's shoe - online hate social media post",
  '/images/slides/activities/thoughtful-posts/thoughtful-2.svg':
    'Black facing online hate social media post',
  '/images/slides/activities/thoughtful-posts/thoughtful-3.svg':
    'Darker skin-toned female with baseball online hate social media post',
  '/images/slides/illustrations/three-little-pigs-characters-smaller-still.svg':
    'Little Red Riding Hood main characters',
  '/images/slides/illustrations/three-little-pigs-characters-smaller.svg':
    'Little Red Riding Hood main characters',
  '/images/slides/illustrations/three-little-pigs-characters.svg':
    'Little Red Riding Hood main characters',
  '/images/slides/activities/social-media-features/tiktok.mp4': 'Scrolling TikTok feed',
  '/images/slides/illustrations/media/tv.svg': '',
  '/images/slides/photos/un-convention-child-rights.png':
    'UN Convention on the Rights of the Child chart',
  '/images/slides/illustrations/unity.svg': 'A happy diverse community',
  '/images/slides/photos/universal-declaration-of-human-rights.jpg': '',
  '/images/slides/illustrations/upset-looking-at-phone.svg':
    'Sad female looking at hateful messages on phone',
  '/images/illustrations/upstander.svg': 'Upstander',
  '/images/slides/photos/vegan-post-2.jpg': 'Social media profile',
  '/images/slides/photos/vegan-post-3.jpg': 'Social media post',
  '/images/slides/photos/vegan-post-alt.jpg': 'Social media post',
  '/images/slides/photos/vegan-post-search.jpg': 'Google search effect of began diets on children',
  '/images/slides/photos/vegan-post-search.png': 'Google search effect of began diets on children',
  '/images/slides/photos/vegan-post.jpg': 'Social media post',
  '/images/slides/placeholder/video-asian-maths.png': '',
  '/images/slides/placeholder/video-girls-robotics.png': '',
  '/images/slides/illustrations/what-is-online-hate.svg':
    'Sad female reading online hate social media posts',
  '/images/slides/illustrations/who-is-involved-in-online-hate.svg': 'Online hate situation roles',
  '/images/slides/illustrations/why-is-it-wrong-to-discriminate.svg':
    'Multiple hands pointing threateningly at an individual',
  '/images/slides/placeholder/wordcloud.png': '',
  '/images/slides/photos/young-australia-news-consumption.jpg':
    'News and Young Australians 2020 infographic',
  '/images/slides/photos/youth-media-chart-1.svg': 'Young people in the news chart',
  '/images/slides/photos/youth-media-chart-2.svg':
    'The voice of young people included in news coverage of young people pie chart',
  '/images/slides/photos/youth-media-chart-3.svg':
    'How young people are represented in mainstream Australian news publications online chart',
  '/images/slides/photos/youth-media-mentions.png':
    'Top 10 keywords used in content about young Australian people chart',
  '/images/slides/photos/youth-media-quotes.png':
    'Misrepresentation of young people in Australia quotes',
  '/images/slides/activities/zahra.jpg': '',
};
